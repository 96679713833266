import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import axios from 'axios';

const Home = lazy(() => import('./Home'));
const App = lazy(() => import('./App'));
const Login = lazy(() => import('./Login'))

// axios.defaults.baseURL = "http://localhost:8002";
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.timeout = 3000;

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/app' element={<App/>}/>
        <Route path='/sign/in' element={<Login/>} component={Login}/>
      </Routes>
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);
